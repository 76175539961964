.app {
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  padding: 2rem;
}

.app > .top {
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
}

.app > .top .download-button {
  margin-left: 1rem;
}
.app > .top .download-button a {
  color: inherit;
  text-decoration: none;
}

.app > .center {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  row-gap: 1rem;
  flex-flow: column;
  margin: 1rem 0;
}

.app > .center > .left {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  border: 1px solid lightgrey;
  width: 100%;
  max-height: 80vh;
}

@media (min-width: 960px) {
  .app > .center > .left {
    max-height: 0;
    min-height: 80vh;
  }
}

.app > .center > .right {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
  border: 1px solid lightgrey;
  flex: 1 1 auto;
}

.app > .bottom {
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td,
th {
  border: 1px solid rgba(211, 211, 211);
  text-align: left;
  padding: 0.25rem 0.5rem;
}

tr:nth-child(even) {
  background-color: rgba(211, 211, 211, 0.2);
}

.input-outline {
  position: relative;
  padding: 1rem;
  border: 1px solid lightgrey;
  border-radius: var(--border-radius);
}

.input-outline > .input-title {
  position: absolute;
  top: -0.6rem;
  left: 1rem;
  font-size: 0.8rem;
  background-color: white;
  padding: 0 0.4rem;
}
